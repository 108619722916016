import { Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { BsFillPersonFill } from "react-icons/bs";
import { FaHeart, FaMapMarkerAlt, FaRegHeart, FaTrain } from "react-icons/fa";
import CartContext from "../context/CartContext";
import CardPillTagSection from "./card-pill-tag-section";
import PopupForm from "./popup-form";
import { Button, Modal } from "react-bootstrap";
import ExpertCard from "./expert-card";
import ExpertCardPopup from "./expert-card-popup";
import { MdOutlineClear } from "react-icons/md";
import PopupFormViewing from "./popup-form-viewing";

const slugify = require("slugify");
function round5(x) {
	return Math.ceil(x / 5) * 5;
}
const ListingsPropertyCardCarousel = ({ property, index }) => {
	const {
		cart,
		onLocationChangeScroll,
		getDistanceFromLatLonInKm,
		OnAddToCart,
		OnRemoveFromCart,
		distancePoint,
		selectedLocation,
		budgetPerDesk,
		numberOfPeople,
	} = React.useContext(CartContext);

	var floors = [];
	if (property?.node?.floorsAvailable !== null) {
		floors =
			property?.node?.floorsAvailable
				?.sort((a, b) => parseFloat(a.rentPM) - parseFloat(b.rentPM))
				.slice() || [];
	}

	const [formSubmitted, setFormSubmitted] = useState(false);
	const [formSubmitted2, setFormSubmitted2] = useState(false);

	const handleFormSuccess = () => {
		setFormSubmitted(true);
	};
	const handleFormSuccess2 = () => {
		setFormSubmitted2(true);
	};
	const lowerCapacity = Math.max(property?.node.desksFrom, numberOfPeople?.min);
	const [vidShow, setVidShow] = useState(false);
	const [formShow, setFormShow] = useState(false);

	const handleVidShow = () => setVidShow(true);
	const handleVidClose = () => setVidShow(false);

	const handleFormShow = () => setFormShow(true);
	const handleFormClose = () => setFormShow(false);
	// const refTb = useRef();
	// const tbShow = useOnScreen(refTb, "0px");
	// useEffect(() => {
	// 	onLocationChangeScroll(
	// 		property.node.locationLatitude,
	// 		property.node.locationLongitude
	// 	);
	// }, [tbShow]); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

	return (
		<div
			style={{ zIndex: "2" }}
			className="bg-white  h-100 px-0 mx-0 position-relative pb-4 carousel-card-shadow"
		>
			<Modal
				style={{ zIndex: 9999999, backgroundColor: "rgba(0, 0, 0, 0.2)" }}
				size="lg"
				dialogClassName="transparent-modal"
				show={vidShow}
				centered
				onHide={handleVidClose}
				className=" w-100 p-4  "
			>
				<div className="position-relative">
					<div
						className="px-3 pt-2 position-absolute end-0 top-0"
						style={{ zIndex: 3 }}
					>
						<MdOutlineClear
							style={{ cursor: "pointer" }}
							className="fs-4"
							onClick={handleVidClose}
						/>
					</div>
					<Modal.Body
						style={{
							overflow: "hidden",

							borderRadius: "12px",
						}}
						className="p-0 w-100  "
						closeButton
					>
						{!formSubmitted ? (
							<>
								<div
									style={{
										borderBottomLeftRadius: "12px",
										borderBottomRightRadius: "12px",
									}}
									className="py-4 px-3 px-md-4 px-lg-5 mb-3 bg-white "
								>
									<Row>
										<Col>
											<h3 className="pb-0 ">
												Book a viewing of{" "}
												<span className="text-dark-blue fw-bold">
													{property?.node.name}
												</span>
											</h3>
										</Col>
									</Row>
									<Row>
										<Col>
											<p>
												Explore our instantly available office spaces at your
												convenience and schedule a flexible viewing today to
												find your ideal workspace.
											</p>
										</Col>
									</Row>
									<PopupFormViewing
										formPropertyName={property?.node.name}
										subject="Book a viewing"
										onSuccess={handleFormSuccess}
									/>
								</div>
								<ExpertCardPopup />
							</>
						) : (
							<div className="py-5 text-center px-3 px-md-4 px-lg-5  bg-white ">
								<h2 className="text-uppercase fs-1 text-primary">Thank You!</h2>
								<p style={{ fontWeight: "600" }} className="pb-4">
									We’ll reach out to you soon!
								</p>
								<p style={{ fontWeight: "600" }}>
									Feel free to call us on{" "}
									<a style={{ fontWeight: "600" }} href="tel:02072062610">
										0207 206 2610
									</a>{" "}
									or email{" "}
									<a
										style={{ fontWeight: "600" }}
										href="mailto:hello@flexofficesolutions.co.uk"
									>
										hello@flexofficesolutions.co.uk
									</a>
								</p>
							</div>
						)}
					</Modal.Body>
				</div>
			</Modal>
			<Modal
				style={{ zIndex: 9999999, backgroundColor: "rgba(0, 0, 0, 0.2)" }}
				size="lg"
				dialogClassName="transparent-modal"
				show={formShow}
				centered
				onHide={handleFormClose}
				className=" w-100 p-4  "
			>
				<div className="position-relative">
					<div
						className="px-3 pt-2 position-absolute end-0 top-0"
						style={{ zIndex: 3 }}
					>
						<MdOutlineClear
							style={{ cursor: "pointer" }}
							className="fs-4"
							onClick={handleFormClose}
						/>
					</div>
					<Modal.Body
						style={{
							overflow: "hidden",

							borderRadius: "12px",
						}}
						className="p-0 w-100  "
						closeButton
					>
						{!formSubmitted2 ? (
							<>
								<div
									style={{
										borderBottomLeftRadius: "12px",
										borderBottomRightRadius: "12px",
									}}
									className="py-4 px-3 px-md-4 px-lg-5 mb-3 bg-white "
								>
									<Row>
										<Col>
											<h3 className="pb-0 ">
												Get a quote for{" "}
												<span className="text-dark-blue fw-bold">
													{property?.node.name}
												</span>
											</h3>
										</Col>
									</Row>
									<Row>
										<Col>
											<p>
												Contact us today to receive a personalised quote and
												find your ideal workspace.
											</p>
										</Col>
									</Row>
									<PopupForm
										formPropertyName={property?.node.name}
										subject="Get a quote"
										onSuccess={handleFormSuccess2}
									/>
								</div>
								<ExpertCardPopup />
							</>
						) : (
							<div className="py-5 text-center px-3 px-md-4 px-lg-5  bg-white ">
								<h2 className="text-uppercase fs-1 text-primary">Thank You!</h2>
								<p style={{ fontWeight: "600" }} className="pb-4">
									We’ll reach out to you soon!
								</p>
								<p style={{ fontWeight: "600" }}>
									Feel free to call us on{" "}
									<a style={{ fontWeight: "600" }} href="tel:02072062610">
										0207 206 2610
									</a>{" "}
									or email{" "}
									<a
										style={{ fontWeight: "600" }}
										href="mailto:hello@flexofficesolutions.co.uk"
									>
										hello@flexofficesolutions.co.uk
									</a>
								</p>
							</div>
						)}
					</Modal.Body>
				</div>
			</Modal>
			<div
				style={{ zIndex: "3" }}
				className="px-3 pt-2 position-absolute end-0 top-0"
			>
				{cart?.filter((e) => e.airtableId === property?.node.airtableId)
					.length > 0 && (
					<FaHeart
						style={{ cursor: "pointer" }}
						onClick={() => OnRemoveFromCart(property.node)}
						className="fs-6 text-dark-blue"
					/>
				)}
				{cart?.filter((e) => e.airtableId === property?.node.airtableId)
					.length < 1 && (
					<FaRegHeart
						style={{ cursor: "pointer" }}
						onClick={() => OnAddToCart(property?.node)}
						className="fs-6 text-med-grey"
					/>
				)}
			</div>
			<Link
				to={`/listings/${slugify(property?.node.name)}-${
					property?.node.fixedId
				}`}
			>
				<div
					className="bg-white h-100 px-0 mx-0"
					onMouseEnter={() => {
						onLocationChangeScroll(
							property?.node.locationLatitude,
							property?.node.locationLongitude
						);
					}}
				>
					<Row
						className="px-0 mx-0 g-0  align-items-center"
						style={{ borderBottom: "1px solid #f5f5f5" }}
					>
						<Col lg={6}>
							<img
								alt="Property Image"
								style={{ height: "300px" }}
								className=" w-100  "
								src={property?.node.photos[0]}
							></img>
						</Col>
						<Col
							className="ps-4 px-lg-4 pt-3 pt-lg-1 pb-1 text-black"
							style={{ minHeight: "100%" }}
							lg={6}
						>
							<div
								style={{ fontSize: "90%" }}
								className="d-flex align-items-center mt-1 text-med-grey pb-3"
							>
								<FaMapMarkerAlt className="me-2 " />
								<p className="d-inline-block pb-0 mb-0  ">
									{selectedLocation?.split(",")[0] || "London"} -{" "}
									{(
										getDistanceFromLatLonInKm(
											distancePoint?.latitude,
											distancePoint?.longitude,
											property?.node.locationLatitude,
											property?.node.locationLongitude
										) * 0.621371
									).toFixed(2)}{" "}
									mi
								</p>
							</div>
							<h3 className="fs-4 pb-2">{property?.node.name}</h3>
							{slugify(property?.node.city).toLowerCase() === "london" && (
								<div>
									{property?.node.subwayStation1 !== null && (
										<div
											style={{ fontSize: "90%" }}
											className="d-flex align-items-center  pb-3"
										>
											<FaTrain className="me-2" />
											<p className="d-inline-block pb-0 mb-0">
												{property?.node.subwayStation1} -{" "}
												{(
													property?.node.subwayStation1DistanceKm * 0.621371
												).toFixed(2)}
												mi
												<span className="d-none d-lg-inline-block">
													({property?.node.subwayStation1DurationMins} walk)
												</span>
											</p>
										</div>
									)}
									{property?.node.subwayStation2 !== null && (
										<div
											style={{ fontSize: "90%" }}
											className="d-none d-lg-flex align-items-center pb-3 mb-2"
										>
											<FaTrain className="me-2" />
											<p className="d-inline-block pb-0 mb-0">
												{property?.node.subwayStation2} -{" "}
												{(
													property?.node.subwayStation2DistanceKm * 0.621371
												).toFixed(2)}
												mi ({property?.node.subwayStation2DurationMins} walk)
											</p>
										</div>
									)}
								</div>
							)}

							<div
								style={{ fontSize: "90%" }}
								className=" align-items-center pb-3 mb-2"
							>
								<BsFillPersonFill className="me-2" />
								<p className="d-inline-block pb-0 mb-0">
									Up to {property?.node.desksTo} desks available
								</p>
							</div>
							<blockquote>
								{property?.node.type === "Serviced Office" &&
									budgetPerDesk?.perDesk && (
										<>
											<p className="mb-0">Private offices from</p>
											<h5 className="mt-1 fs-4">
												£
												{`${
													property?.node.rentPM === null ||
													property?.node.rentPM === undefined
														? "POA"
														: `${round5(property?.node.rentPM).toLocaleString(
																"en-UK"
														  )}pcm`
												}`}
											</h5>
										</>
									)}
								{property?.node.type === "Serviced Office" &&
									!budgetPerDesk?.perDesk && (
										<>
											<p className="mb-0">Private offices from</p>
											<h5 className="mt-1 fs-4">
												£
												{`${
													property?.node.rentPM === null ||
													property?.node.rentPM === undefined
														? "POA"
														: `${round5(
																property?.node.rentPM * lowerCapacity
														  ).toLocaleString("en-UK")}pcm`
												}`}
											</h5>
										</>
									)}
								{(property?.node.type === "Managed Office" ||
									property?.node.type === "Flex Lease") && (
									<>
										<p className="mb-0">Private floors from</p>
										<h5 className="mt-1 fs-4">
											£
											{`${
												floors[0]?.rentPM === null ||
												floors[0]?.rentPM === undefined
													? "POA"
													: `${round5(floors[0]?.rentPM).toLocaleString(
															"en-UK"
													  )}pcm`
											}`}
										</h5>
									</>
								)}
							</blockquote>
						</Col>
					</Row>
					<Row className="pb-0 pt-0 my-2 align-items-center">
						<CardPillTagSection features={property?.node.features} oneLine />
					</Row>
				</div>
			</Link>
			<Row className="d-md-none">
				<Col className="px-4 mt-3  d-md-flex justify-content-between">
					<Button
						onClick={handleFormShow}
						className={`text-white btn btn-primary  me-md-3  w-100 w-md-50 fw-bold `}
					>
						Get a quote
					</Button>
					<Button
						onClick={handleVidShow}
						className={`text-white mt-3 mt-md-0 btn btn-dark-blue   w-100 w-md-50 fw-bold `}
					>
						Book a viewing
					</Button>
				</Col>
			</Row>
			<div style={{ height: "38px" }} className="w-100 d-none d-md-block"></div>
			<div className="position-absolute d-none  d-md-block pb-4 w-100 start-0 bottom-0">
				<Row>
					<Col className="px-4 mt-3  d-md-flex justify-content-between">
						<Button
							onClick={handleFormShow}
							className={`text-white btn btn-primary  me-md-3  w-100 w-md-50 fw-bold `}
						>
							Get a quote
						</Button>
						<Button
							onClick={handleVidShow}
							className={`text-white mt-3 mt-md-0 btn btn-dark-blue   w-100 w-md-50 fw-bold `}
						>
							Book a viewing
						</Button>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default ListingsPropertyCardCarousel;
