import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import {
	Button,
	Col,
	Container,
	DropdownButton,
	Form,
	Row,
	Spinner,
} from "react-bootstrap";
import { useGeolocated } from "react-geolocated";
import { BiCurrentLocation } from "react-icons/bi";
import { BsCurrencyPound } from "react-icons/bs";
import { FaBuilding } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { MdPlayArrow } from "react-icons/md";
import styled from "styled-components";
import CartContext from "../context/CartContext";
import HeroFilterTypedInput from "./hero-filter-input";
import InputField from "./inputField";

const HeroFilters = ({ extendedCallback, title }) => {
	const {
		cart,
		settingVal,
		selectedOfficeType,
		selectedFeatures,
		viewport,

		budgetMinMax,
		onLocationChange,
		numberOfPeople,
		budgetPerDesk,
		options,
		filteredOffices,
		newOptions,
		// budgetMinMax,
		// capacityMinMax,
	} = React.useContext(CartContext);

	//   const setCapacityHelper = (numPeople) => {
	//     return {
	//       min:
	//         numPeople?.min > 0
	//           ? numPeople?.min
	//           : capacityMinMax.minPropertyCapacity,
	//       max:
	//         numPeople?.max > 0
	//           ? numPeople?.max
	//           : capacityMinMax.maxPropertyCapacity,
	//     };
	//   };

	const [capacityMin, setCapacityMin] = useState(numberOfPeople?.min);
	const [turnArrow, setTurnArrow] = useState(false);
	const [typesTurnArrow, setTypesTurnArrow] = useState(false);

	// const [show, setShow] = useState(false);

	// const handleClose = () => setShow(false);
	// const handleShow = () => setShow(true);

	// useEffect(() => {
	// 	if (capacityMin > 75) {
	// 		handleShow();
	// 	}
	// }, [capacityMin]);
	//   const setBudgetHelper = (budget) => {
	//     return {
	//       min: budget?.min > 0 ? budget?.min : budgetMinMax?.minPropertyBudget,
	//       max: budget?.max > 0 ? budget?.max : budgetMinMax?.maxPropertyBudget,
	//       perDesk: budget?.perDesk,
	//     };
	//   };

	//   const [budgetLimits, setBudgetLimits] = useState({});
	//   console.log("budgetLimits", budgetLimits, "budgetMinMax", budgetMinMax);
	//   const [perDesk, setTotal] = useState(budgetPerDesk.perDesk);
	const [budgetMax, setBudgetMax] = useState(budgetPerDesk?.max);
	const [budgetMaxPerDesk, setBudgetMaxPerDesk] = useState(
		budgetPerDesk?.maxPerDesk
	);

	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
		useGeolocated({
			positionOptions: {
				enableHighAccuracy: true,
			},
			isOptimisticGeolocationEnabled: false,
			userDecisionTimeout: 5000,
		});

	const OnLocationChangeGeo = () => {
		if (isGeolocationAvailable && isGeolocationEnabled && coords) {
			onLocationChange(coords.latitude, coords.longitude);
			settingVal("Your location", "locationValue");
		} else {
			alert("Please allow access to your location on your browser");
		}
	};

	const typeItems = ["Any", "Serviced Office", "Managed Office", "Flex Lease"];

	const [showMore, setShowMore] = useState(false);
	const [type, setType] = useState(selectedOfficeType || "Any");

	const [showSpinner, setShowSpinner] = useState(false);

	const [radiosVisible, setRadiosVisible] = useState(false);
	const [radiosVisibleMore, setRadiosVisibleMore] = useState(false);

	const [typesVisible, setTypesVisible] = useState(false);
	const [turnArrowNew, setTurnArrowNew] = useState(false);
	const [typesVisibleMore, setTypesVisibleMore] = useState(false);

	const [perDesk, setPerDesk] = useState(budgetPerDesk?.perDesk);
	const radioSetter = () => {
		setPerDesk(!perDesk);
	};

	//   useEffect(() => {
	//     setBudgetLimits(budgetMinMax);
	//   }, [budgetLimits]);

	//   useEffect(() => {
	//     console.log("activated");
	//     setBudgetLimits(budgetMinMax);
	//   }, [perDesk]);

	const handleSearchClick = () => {
		const newBudget = {
			min: 0,
			max: budgetMax || budgetMinMax.max,
			maxPerDesk: budgetMaxPerDesk || budgetMinMax.maxPerDesk,
			minPerDesk: 0,
			perDesk: perDesk,
		};

		settingVal(newBudget, "budgetPerDesk");
		// const newCapacity = { ...numberOfPeople, min: capacityMin };
		const newCapacity = { ...numberOfPeople, min: capacityMin || 0 };

		settingVal(newCapacity, "numberOfPeople");
		settingVal(type, "selectedOffice");

		setShowSpinner(true);
	};

	const radiosRef = useRef(null);
	const poundSignRef = useRef(null);
	const poundSignRefMore = useRef(null);
	const radiosRefMore = useRef(null);
	const typesRef = useRef(null);
	const typesExpanderRef = useRef(null);

	useEffect(() => {}, [budgetMax, capacityMin]);

	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutsideMore(event) {
			if (
				poundSignRefMore.current &&
				!poundSignRefMore.current.contains(event.target) &&
				radiosRefMore.current &&
				!radiosRefMore.current.contains(event.target)
			) {
				// onClickOutside && onClickOutside();
				// console.log("some text");
				setRadiosVisibleMore(false);
				setTurnArrow(false);
			}
		}
		function handleClickOutside(event) {
			if (
				poundSignRef.current &&
				!poundSignRef.current.contains(event.target) &&
				radiosRef.current &&
				!radiosRef.current.contains(event.target)
			) {
				// onClickOutside && onClickOutside();
				// console.log("some text");
				setRadiosVisible(false);
				setTurnArrow(false);
			}
		}
		const handleClicksOutside = (e) => {
			handleClickOutside(e);
			handleClickOutsideMore(e);
		};

		const turnArrowNew = (e) => {};

		// Bind the event listener
		document.addEventListener("mousedown", handleClicksOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<section className="position-relative h-100">
			{/* <Modal
				style={{ zIndex: 99999 }}
				size="lg"
				show={show}
				onHide={handleClose}
				centered
			>
				<Modal.Body
					style={{ borderRadius: "20px" }}
					className="bg-light-grey"
					closeButton
				>
					<div className="p-lg-4 p-2  pt-md-0 pt-4  position-relative">
						<div className="position-absolute end-0 top-0 p-lg-2">
							<MdOutlineClear
								style={{ cursor: "pointer" }}
								className="fs-4 "
								onClick={handleClose}
							/>
						</div>
						<p className="fs-5 d-none d-md-block">
							If you are trying to find space for more than 75 people it would
							be best to contact us directly using this form.
						</p>
						<p className="d-md-none">
							If you are trying to find space for more than 75 people it would
							be best to contact us directly using this form.
						</p>
						<ContactFormHome />
					</div>
				</Modal.Body>
			</Modal> */}
			<div
				style={{ zIndex: 0 }}
				className="position-relative h-100 d-flex align-items-end w-100 "
			>
				<Container className="position-relative h-100">
					<div className="position-absolute start-50 top-50 px-4 px-md-0 w-100 translate-middle ">
						<Row className="align-items-center py-2">
							<Col xl={8}>
								<h1 className="text-white fw-bold display-3 ">
									{title || "Find your ideal office with Flex Office Solutions"}
								</h1>
								{/* <p style={{ fontSize: "1.2rem" }} className="text-white">
                Find your perfect office solution with the UK's most trusted
                brokerage.
              </p> */}
							</Col>
						</Row>

						<Row
							className=" w-100 h-100 d-flex justify-content-between align-items-center"
							// style={{ height: "70px" }}
						>
							<Col xl={5}>
								<div
									className="py-1  ps-sm-3 ps-lg-0 pe-1 pe-sm-3 pe-lg-5 w-100 rounded my-2"
									style={{ border: "2px solid white", height: "70px" }}
								>
									<Row className="w-100 h-100 mx-0 mx-sm-2 align-items-center">
										<Col xs={11}>
											<InputField color={"text-white opacity-100"} />
										</Col>
										<Col
											className="px-0 px-sm-2 text-center  text-sm-end text-lg-center"
											xs={1}
										>
											{" "}
											<a
												className="align-self-center"
												style={{ cursor: "pointer" }}
												onClick={() => OnLocationChangeGeo()}
											>
												<BiCurrentLocation className="fs-3 d-none d-sm-block white-link" />
												<BiCurrentLocation className="fs-5 d-sm-none white-link" />
											</a>
										</Col>
									</Row>
								</div>
							</Col>

							<Col xl={3} className="d-none d-xl-inline-block">
								<div
									className="w-100 my-2 px-3 bg-t rounded"
									style={{ border: "2px solid white", height: "70px" }}
								>
									<div className="d-flex align-content-center h-100">
										<IoIosPeople
											className={`fs-2 text-white opacity-100 align-self-center`}
										/>
										<HeroFilterTypedInput
											setter={setCapacityMin}
											value={capacityMin}
											defaultText="Capacity"
										/>
										{/* <input
                    style={{ border: "0px solid #58B9FA" }}
                    className=" ssp-bold w-80 ps-2 text-white fs-5 bg-t"
                    placeholder="Capacity"
                    type="text"
                    value={capacity}
                    onChange={(e) => {
                      settingVal(e.target.value, "numberOfPeople");
                      setCapacity(e.target.value);
                    }}
                    onFocus={(e) => {
                      e.currentTarget.type = "number";
                      if (capacity === "Capacity") {
                        setCapacity("");
                      }
                    }}
                    onBlur={(e) => {
                      e.currentTarget.type = "text";
                      if (
                        Number.isInteger(Number(capacity)) &&
                        capacity !== ""
                      ) {
                        setCapacity(capacity);
                      } else {
                        setCapacity("Capacity");
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") e.target.blur();
                    }}
                  /> */}
									</div>
								</div>
							</Col>
							<Col xl={3} className="d-none d-xl-inline-block">
								<div
									className="w-100 my-2 px-3 bg-t position-relative rounded"
									style={{ border: "2px solid white", height: "70px" }}
								>
									<div
										className="d-flex align-items-center h-100"
										ref={poundSignRef}
									>
										<BsCurrencyPound
											className={`fs-2 opacity-100 align-self-center text-white`}
										/>
										<HeroFilterTypedInput
											setter={setBudgetMax}
											setter2={setBudgetMaxPerDesk}
											value={budgetMax}
											value2={budgetMaxPerDesk}
											perDesk={perDesk}
											defaultText="Per month"
											budget
										/>
										<MdPlayArrow
											className={` fs-5 text-white pound-sign ${
												turnArrow ? "cheveron-up" : "cheveron-down "
											}`}
											onClick={(e) => {
												e.preventDefault();
												setRadiosVisible(!radiosVisible);
												setTurnArrow(!turnArrow);
											}}
										/>
										{radiosVisible && (
											<div
												className="position-absolute whiteish bg-white ps-3 pound-sign--dropdown "
												//   onClick={(e) => e.preventDefault()}
												ref={radiosRef}
											>
												<Row>
													<Col>
														<h3 className="text-primary fs-5">
															Monthly budget
														</h3>
													</Col>
												</Row>
												<Row>
													<Col xs={12}>
														<Form.Check
															checked={!perDesk}
															onClick={radioSetter}
															type="radio"
															label="Total"
															className="pb-1"
														/>
													</Col>
													<Col xs={12}>
														<Form.Check
															checked={perDesk}
															onClick={radioSetter}
															type="radio"
															label="Per Desk"
															className="pb-1"
														/>
													</Col>
												</Row>
											</div>
										)}
									</div>
								</div>
							</Col>
							{/* 
						<Col lg={3} className="d-none d-xl-inline-block">
							<DropdownButton
								onToggle={() => {
									setTurnArrowNew(!turnArrowNew);
								}}
								className={`px-2 filter-button-div prop-dropdown-2 text-white  py-0 py-0 drop-font-size d-flex justify-content-start align-items-center hero-dropdown`}
								style={{
									border: "2px solid white",
									height: "70px",
								}}
								variant="none"
								title={
									<div className="w-100 h-100 d-flex align-items-center ">
										<Row className="w-100 h-100 align-items-center pt-4">
											<Col xs={10}>
												<div className="w-100 d-flex align-items-center">
													<FaBuilding
														style={{ minWidth: "17.5px", minHeight: "20px" }}
														className="fs-5 me-3 mb-1"
													/>
													<span className="fs-5 ">
														{type === "Select Office Type" ? "Type" : type}
													</span>
												</div>
											</Col>
											<Col className="text-end" xs={2}>
												<MdPlayArrow
													className={` fs-5 text-white mx-auto pound-sign ${
														turnArrowNew ? "cheveron-up" : "cheveron-down "
													}`}
												/>
											</Col>
										</Row>
									</div>
								}
							>
								<Row className="">
									<Col>
										<div
											style={{
												borderRadius: "15px",
											}}
											className="px-4 bg-white py-3 border border-new-grey"
										>
											<div style={{ width: "100%" }}>
												<Row>
													<Col>
														<h3 className="text-primary fs-5">Office Type</h3>
													</Col>
												</Row>
												<Row>
													<Col xs={12}>
														<Form.Check
															checked={type === "Any" ? true : false}
															onClick={
																() => setType("Any")
																// settingVal("Any", "selectedOffice")
															}
															type="radio"
															label="Any"
															className="pb-1"
														/>
													</Col>
													<Col xs={12}>
														<Form.Check
															checked={
																type === "Serviced Office" ? true : false
															}
															onClick={() => setType("Serviced Office")}
															type="radio"
															label="Serviced Office"
															className="pb-1"
														/>
													</Col>
													<Col xs={12}>
														<Form.Check
															checked={type === "Managed Office" ? true : false}
															onClick={() => setType("Managed Office")}
															type="radio"
															label="Managed Office"
															className="pb-1"
														/>
													</Col>
												</Row>
											</div>
										</div>
									</Col>
								</Row>
							</DropdownButton>
						</Col> */}

							{/* <Col xl={2} className="d-none d-xl-inline-block">
              <AccordianDropdownSlider
                icon={
                  <IoIosPeople
                    className={`fs-2 text-white opacity-100 align-self-center`}
                  />
                }
                values={capacity}
                valId="numberOfPeople"
                width=" w-100 "
                minMaxLimits={capacityMinMax}
                title="Capacity"
              />
            </Col> */}

							{/* <Col lg={2} className="d-none d-xl-inline-block"> */}
							{/* <div
                className="  w-100 my-2 bg-t px-3 "
                style={{
                  border: "2px solid white ",
                  height: "70px",
                }}
              >
                <div className="d-flex align-content-center h-100">
                  <BsCurrencyPound
                    className={`fs-2 text-white opacity-100 align-self-center`}
                  />
                  <input
                    style={{ border: "0px solid #58B9FA" }}
                    className=" ssp-bold w-80 ps-2 text-white fs-5 bg-t"
                    placeholder="Budget"
                    type="text"
                    value={budget}
                    onChange={(e) => {
                      settingVal(e.target.value, "budgetPerDesk");
                      setBudget(e.target.value);
                    }}
                    onFocus={(e) => {
                      e.currentTarget.type = "number";
                      if (budget === "Budget") {
                        setBudget("");
                      }
                    }}
                    onBlur={(e) => {
                      e.currentTarget.type = "text";
                      if (Number.isInteger(Number(budget)) && budget !== "") {
                        setBudget(budget);
                      } else {
                        setBudget("Budget");
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") e.target.blur();
                    }}
                  />
                </div>
              </div> */}
							{/* <AccordianDropdownSlider
                icon={
                  <BsCurrencyPound
                    className={`fs-2 text-white opacity-100 align-self-center`}
                  />
                }
                values={budget}
                valId="budgetPerDesk"
                width=" w-100 "
                minMaxLimits={budgetMinMax}
                radio
                title="Budget"
              />
            </Col> */}

							{/* <Col
              className="pb-0 d-none d-xl-inline-block position-relative"
              lg={3}
            >
              <div className="  w-100 my-2">
                <AccordianDropdown
                  icon={<FaBuilding className="text-white fs-5 py-0 " />}
                  items={typeItems}
                  val={selectedOfficeType}
                  valId="selectedOffice"
                  width=" w-100 "
                />
              </div>
            </Col> */}

							{showMore && (
								<>
									<Col md={4} className="d-xl-none">
										<div
											className="  w-100 my-2 px-3 bg-t rounded"
											style={{ border: "2px solid white", height: "70px" }}
										>
											<div className="d-flex align-content-center h-100">
												<IoIosPeople
													className={`fs-2 text-white opacity-100 align-self-center`}
												/>
												<HeroFilterTypedInput
													setter={setCapacityMin}
													value={capacityMin}
													defaultText="Capacity"
												/>
											</div>
										</div>
									</Col>

									<Col md={4} className="d-xl-none mb-2">
										<div
											className="  w-100 my-2 bg-t ps-3 position-relative rounded"
											style={{
												border: "2px solid white ",
												height: "70px",
											}}
										>
											<div
												className="d-flex align-items-center w-100 h-100"
												ref={poundSignRefMore}
											>
												<BsCurrencyPound
													className={`fs-2 opacity-100 align-self-center text-white`}
												/>
												<HeroFilterTypedInput
													setter={setBudgetMax}
													setter2={setBudgetMaxPerDesk}
													value={budgetMax}
													value2={budgetMaxPerDesk}
													perDesk={perDesk}
													defaultText="Budget"
													budget
												/>
												<MdPlayArrow
													className={` fs-5 text-white mx-auto pound-sign ${
														turnArrow ? "cheveron-up" : "cheveron-down "
													}`}
													onClick={(e) => {
														e.preventDefault();
														setRadiosVisibleMore(!radiosVisibleMore);
														setTurnArrow(!turnArrow);
													}}
												/>
												{radiosVisibleMore && (
													<div
														className="position-absolute whiteish bg-white pound-sign--dropdown "
														//   onClick={(e) => e.preventDefault()}
														ref={radiosRefMore}
													>
														<Row>
															<Col>
																<h3 className="text-primary fs-6">
																	Monthly budget
																</h3>
															</Col>
														</Row>
														<Row>
															<Col xs={6}>
																<Form.Check
																	checked={!perDesk}
																	onClick={radioSetter}
																	type="radio"
																	label="Total"
																	className="pb-1"
																/>
															</Col>
															<Col xs={6}>
																<Form.Check
																	checked={perDesk}
																	onClick={radioSetter}
																	type="radio"
																	label="Per Desk"
																	className="pb-1"
																/>
															</Col>
														</Row>
													</div>
												)}
												{/* <input
                        style={{ border: "0px solid #58B9FA" }}
                        className=" ssp-bold w-80 ps-2 text-white fs-5 bg-t"
                        placeholder="Budget"
                        type="text"
                        value={budget}
                        onChange={(e) => {
                          settingVal(e.target.value, "budgetPerDesk");
                          setBudget(e.target.value);
                        }}
                        onFocus={(e) => {
                          e.currentTarget.type = "number";
                          if (budget === "Budget") {
                            setBudget("");
                          }
                        }}
                        onBlur={(e) => {
                          e.currentTarget.type = "text";
                          if (
                            Number.isInteger(Number(budget)) &&
                            budget !== ""
                          ) {
                            setBudget(budget);
                          } else {
                            setBudget("Budget");
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.target.blur();
                        }}
                      /> */}
											</div>
										</div>
									</Col>

									{/* <Col className="pb-0 mb-2 d-xl-none" md={4}>
									<DropdownButton
										onToggle={() => {
											setTurnArrowNew(!turnArrowNew);
										}}
										className={`px-2 filter-button-div prop-dropdown-1 text-white  py-0 drop-font-size d-flex justify-content-start align-items-center hero-dropdown hero-show-more`}
										style={{
											border: "2px solid white",
											height: "70px",
										}}
										variant="none"
										title={
											<div className="w-100 h-100 d-flex align-items-center ">
												<Row className="w-100 h-100 align-items-center pt-4">
													<Col className="text-start ps-3" xs={8}>
														<FaBuilding className="fs-5 me-3 mb-1" />
														<span className="fs-5">
															{type === "Select Office Type" ? "Type" : type}
														</span>
													</Col>
													<Col className="text-end" xs={4}>
														<MdPlayArrow
															className={` fs-5 text-white mx-auto pound-sign ${
																turnArrowNew ? "cheveron-up" : "cheveron-down "
															}`}
														/>
													</Col>
												</Row>
											</div>
										}
									>
										<Row className="">
											<Col>
												<div
													style={{
														borderRadius: "15px",
													}}
													className="px-4 bg-white py-3 border border-new-grey"
												>
													<div style={{ width: "100%" }}>
														<Row>
															<Col>
																<h3 className="text-primary fs-5">
																	Office Type
																</h3>
															</Col>
														</Row>
														<Row>
															<Col xs={12}>
																<Form.Check
																	checked={type === "Any" ? true : false}
																	onClick={
																		() => setType("Any")
																		// settingVal("Any", "selectedOffice")
																	}
																	type="radio"
																	label="Any"
																	className="pb-1"
																/>
															</Col>
															<Col xs={12}>
																<Form.Check
																	checked={
																		type === "Serviced Office" ? true : false
																	}
																	onClick={() => setType("Serviced Office")}
																	type="radio"
																	label="Serviced Office"
																	className="pb-1"
																/>
															</Col>
															<Col xs={12}>
																<Form.Check
																	checked={
																		type === "Managed Office" ? true : false
																	}
																	onClick={() => setType("Managed Office")}
																	type="radio"
																	label="Managed Office"
																	className="pb-1"
																/>
															</Col>
														</Row>
													</div>
												</div>
											</Col>
										</Row>
									</DropdownButton>
									{/* <div className="  w-100 my-2 bg-t ">
                    <AccordianDropdown
                      icon={<FaBuilding className="text-white fs-5 py-0 " />}
                      items={typeItems}
                      val={selectedOfficeType}
                      valId="selectedOffice"
                      width=" w-100 "
                    />
                  </div> */}
									{/* </Col>  */}
								</>
							)}

							<Col xl={1} lg={4}>
								<Button
									onClick={() => handleSearchClick()}
									as={Link}
									to="/properties"
									className="btn btn-primary text-white text-center rounded d-inline-block w-100  mt-1 mt-xl-2 mb-2"
									style={{ height: "70px" }}
								>
									{showSpinner === false && (
										<div className="h-100 w-100 fw-bold d-flex align-items-center justify-content-center">
											Search
										</div>
									)}
									{showSpinner === true && (
										<div className="h-100 w-100 d-flex align-items-center justify-content-center">
											<Spinner animation="border" variant="white" />
										</div>
									)}
								</Button>
								<p
									className={`d-xl-none text-white text-center text-md-start ps-md-4 pt-2  ${
										showMore ? "d-none" : ""
									}`}
									style={{ cursor: "pointer" }}
									onClick={() => {
										setShowMore(true);
										extendedCallback(true);
									}}
								>
									<u>Show filters</u>
								</p>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
		</section>
	);
};

export default HeroFilters;

const OptionWrapper = styled.div`
	/* background: #00a8a8ad; */
	margin-top: 18px;
	border-radius: 10px;
	border: 1px black solid;
	background: #ffffffad !important;
	position: absolute;
	top: 100%;
	width: 90%;
	padding: 0px 10px 0px 10px;
	margin: 20px 10px 10px 10px;
	z-index: 2;
`;

const Option = styled.p`
	cursor: pointer;
	max-width: 100%;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	z-index: 2;
`;
