import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import SafeHtmlParser from "./safeHtmlParser";

const Team = ({ title, teamCards }) => {
	const data = useStaticQuery(graphql`
		query {
			london: wpMediaItem(title: { eq: "Canary Wharf" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 30
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			matt: wpMediaItem(title: { eq: "Matt New" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			andrew: wpMediaItem(title: { eq: "Andrew New" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			tony: wpMediaItem(title: { eq: "Tony" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
		}
	`);

	const andrew = data.andrew?.localFile.childImageSharp.gatsbyImageData;
	const matt = data.matt?.localFile.childImageSharp.gatsbyImageData;
	const tony = data.tony?.localFile.childImageSharp.gatsbyImageData;

	return (
		<Container className="px-4 px-md-0">
			<Row className="pb-6">
				<Col className="text-center">
					<h2 className="fs-1">Meet the team</h2>
				</Col>
			</Row>
			<Row className="px-0 pb-6 g-6 justify-content-center">
				{teamCards && teamCards.length > 0 && (
					<>
						{teamCards.map((item, i) => (
							<Col lg={4} md={10} sm={12} style={{ minHeight: "100%" }} key={i}>
								<div
									className="text-center px-0 position-relative"
									style={{ minHeight: "100%" }}
								>
									<div
										style={{ overflow: "hidden" }}
										className="w-100 rounded-circle"
									>
										<GatsbyImage
											image={
												item.featuredImage?.node.localFile.childImageSharp
													.gatsbyImageData
											}
											className="rounded-circle mx-auto mb-4 greyImg"
											style={{
												maxHeight: "150px",
												maxWidth: "150px",
												minHeight: "150px",
												minWidth: "150px",
											}}
											alt={item.featuredImage?.node?.altText}
										/>
									</div>
									<h5>{item.title}</h5>
									<p>{item.ourTeamFields?.teamMemberDesignation}</p>
									<SafeHtmlParser
										htmlContent={item.ourTeamFields?.teamMemberAbout}
									/>
									<div
										className="d-lg-none d-xl-block"
										style={{ height: "7rem" }}
									></div>
									<div
										className="d-none d-lg-block d-xl-none"
										style={{ height: "8rem" }}
									></div>
									<div className="position-absolute bottom-0 px-0 w-100">
										<p className="fs-5">
											Phone:{" "}
											<a
												className="text-decoration-none link fw-bold"
												href={`tel:${item.ourTeamFields?.teamMemberPhone}`}
											>
												{item.ourTeamFields?.teamMemberPhone}
											</a>
										</p>
										<p className="fs-5">
											E-mail:{" "}
											<a
												className="text-decoration-none link fw-bold"
												href={`mailto:${item.ourTeamFields?.teamMemberEmail}`}
											>
												{item.ourTeamFields?.teamMemberEmail}
											</a>
										</p>
									</div>
								</div>
							</Col>
						))}
					</>
				)}
			</Row>
		</Container>
	);
};

export default Team;
