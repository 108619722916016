import gsap from "gsap";
import React, { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import styled from "styled-components";
import CartContext from "../context/CartContext";
import useInput from "./useInput";
import { navigate } from "gatsby";

const InputFieldHome = ({ color, propertiesPage, staticPage, placeholder }) => {
	const address = useInput("");
	const { settingVal, selectedLocation, onLocationChange } =
		React.useContext(CartContext);
	const placeholderText = placeholder || "Address";
	const topM = propertiesPage ? "4px" : "10px";
	const widthNew = propertiesPage ? "widthNew-100" : "widthNew-110";
	const [tempLocation, setTempLocation] = useState(selectedLocation);
	const [showPred, setShowPred] = useState(false);
	const [defaultLocation, setDefaultLocation] = useState({});

	const OnSearching = (val) => {
		setTempLocation(val);
		if (showPred === false) {
			setShowPred(true);
		}
		getPlacePredictions({ input: val });
		setDefaultLocation(
			placePredictions.filter((e) =>
				e.terms.find((f) => f.value === "London")
			)[0]
		);
	};

	const HandleKeyDown = (event) => {
		if (event.key === "Enter" || event.keyCode === 13) {
			navigate("/properties");
		}
	};

	const HandleSearchClick = () => {
		navigate("/properties");
	};

	const HideSuggestions = (val) => {
		gsap.to(".suggestions", { display: "none", duration: 0.1 });
	};

	const center = { lat: 51.5120205, lng: -0.1499933 };

	const {
		placesService,
		placePredictions,
		getPlacePredictions,
		isPlacePredictionsLoading,
	} = usePlacesService({
		apiKey: "AIzaSyB_x8XuP-DH1f-7hUui3U2LlL_KegsVbwU",
		options: {
			types: ["establishment", "geocode"],
			componentRestrictions: { country: "GB" },
		},
	});

	useEffect(() => {
		setTempLocation(selectedLocation);
	}, [selectedLocation]);

	useEffect(() => {
		if (tempLocation === selectedLocation) {
			gsap.to(".suggestions", { display: "none", duration: 0.1 });
		} else if (tempLocation !== selectedLocation) {
			gsap.to(".suggestions", {
				autoAlpha: 1,
				display: "block",
				duration: 0.1,
			});
		}
	}, [tempLocation]);

	return (
		<Wrapper className="mx-0 position-relative w-100 d-flex align-items-center ps-0 py-0 my-0">
			<Input
				onKeyDown={(evt) => {
					HandleKeyDown(evt);
				}}
				placeholder={placeholderText}
				value={tempLocation}
				onChange={(evt) => {
					OnSearching(evt.target.value);
				}}
				isTyping={tempLocation !== ""}
				className={`${
					color || "text-black"
				} bg-t border-none py-2  pe-0 pe-md-2 fw-light w-100 inputSearch`}
			/>
			{placePredictions?.length > 0 && showPred && (
				<SuggestionWrapper
					className={`suggestions ps-md-5 ps-4 py-md-2 pb-3 ${widthNew}`}
					style={{ marginTop: topM }}
				>
					{placePredictions.map((suggestion, index) => {
						return (
							<div key={index}>
								{propertiesPage && (
									<FaMapMarkerAlt className="position-absolute opacity-25 ms-3 ms-md-4 start-0" />
								)}
								<Suggestion
									className="padding-change"
									onClick={() => {
										settingVal(
											suggestion.description.split(",").slice(0, 2).join(","),
											"locationValue"
										);
										if (propertiesPage === true) {
											window.history.replaceState(
												null,
												null,
												"?location=searched"
											);
										}
										if (staticPage === true) {
											window.location = "/properties";
										}
										placesService.getDetails(
											{ placeId: suggestion.place_id },
											(placeDetails) =>
												onLocationChange(
													placeDetails.geometry.location.lat(),
													placeDetails.geometry.location.lng()
												)
										);
									}}
								>
									{suggestion.description.split(",").slice(0, 2).join(",")}
								</Suggestion>
							</div>
						);
					})}
				</SuggestionWrapper>
			)}

			<FiSearch
				style={{ cursor: "pointer", color: "#F28B2D" }}
				onClick={() => {
					HandleSearchClick();
				}}
				className={`fs-2 ms-0  ${color}`}
			/>
		</Wrapper>
	);
};

export default InputFieldHome;

const Wrapper = styled.div`
	font-family: "houschka-rounded", sans-serif;
	font-weight: 500;
	font-style: normal;
	margin: 0;
`;

const Input = styled.input`
	width: 100%;
	background: none !important;
	border: none;
	position: relative;
	display: grid;
	justify-self: center;
	&:focus {
		outline: none;
		border-radius: ${(props) => props.isTyping && "10px 10px 0px 0px"};
	}
`;

const SuggestionWrapper = styled.div`
	margin-bottom: 10px;
	border-radius: 0px;
	background: white;
	position: absolute;
	top: 100%;
	z-index: 5;
`;

const Suggestion = styled.p`
	cursor: pointer;
	max-width: 100%;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	z-index: 2;
`;
