import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { BgImage } from "gbimage-bridge";
import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/layout";
import TickIcon from "../images/circleTick.svg";
import StarsIcon from "../images/5stars.svg";
// import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import "mapbox-gl/dist/mapbox-gl.css";
import { Helmet } from "react-helmet";
import slugify from "slugify";
import { FiCalendar, FiHeart } from "react-icons/fi";
import ReactPlayer from "react-player";
import { useGeolocated } from "react-geolocated";
import { BsPeople } from "react-icons/bs";

import Team from "../components/team";
import Testimonials from "../components/testimonials";
import CartContext from "../context/CartContext";
import { FiSearch } from "react-icons/fi";
import ListingsPropertyCard from "../components/Listings-property-card";
import AnimatedImageCard from "../components/animated-image-card";
import ContactFormHome from "../components/contact-form-home";
import FeaturedProperties from "../components/featured-properties";
import HeroFilters from "../components/hero-filters";
import PreferToTalk from "../components/prefer-to-talk";
import { Carousel, Spinner } from "react-bootstrap";
import { checkPropertiesForNull } from "../../utils";
import SafeHtmlParser from "../components/safeHtmlParser";
import ExpertCard from "../components/expert-card";
import InputField from "../components/inputField";
import InputFieldHome from "../components/input-field-home";

const IndexPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			allAirtableProperties(
				filter: {
					isFloor: { nin: 1 }
					hasFeatures: { eq: 1 }
					hasPhotos: { eq: 1 }
					live: { eq: true }
				}
			) {
				edges {
					node {
						addressLineOne
						desksFrom
						desksTo
						live
						fixedId
						subwayStation1
						subwayStation1DistanceKm
						subwayStation1DurationMins
						subwayStation2
						subwayStation2DistanceKm
						subwayStation2DurationMins
						subwayStation3
						subwayStation3DistanceKm
						subwayStation3DurationMins
						type
						rentPM
						airtableId
						locationLatitude
						locationLongitude
						name
						provider
						city
						features
						description
						photos
					}
				}
			}
			wpFeaturedOffice {
				featuredOfficeFields {
					firstFeaturedOffice
					fourthFeaturedOffice
					secondFeaturedOffice
					thirdFeaturedOffice
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "homepage" }) {
				homepageFields {
					homeBanner {
						homeBannerHeading
						homeBannerBackgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						homeBannerBackgroundImage2 {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						homeBannerBackgroundImage3 {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					homeContentSection {
						aboveCardsContent
						ctaButton {
							title
							url
						}
						homeContentSectionCards {
							cardTitle
							cardDescription
							cardIcon {
								altText
								sourceUrl
							}
						}
					}
					homeLeftVideoAndRightContent {
						homeSectionRightHeading
						aboveVideoContent
						homeSectionRightContent {
							items
						}
						homeSectionRightContentContactButton {
							title
							target
							url
						}
						homeSectionRightContentSearchOfficeButton {
							title
							target
							url
						}
						homeSectionLeftVideo {
							altText
							mediaItemUrl
						}
					}
					homeFeaturedOfficeSpaces {
						homeFeaturedOfficeSpacesHeading
					}
					homeClientTestimonials {
						homeClientTestimonialsHeading
					}
					homeMeetTheTeam {
						homeMeetTheTeamHeading
						homeMeetTheTeamCards {
							... on WpOurTeam {
								title
								featuredImage {
									node {
										altText
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 30
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
								ourTeamFields {
									teamMemberAbout
									teamMemberDesignation
									teamMemberEmail
									teamMemberPhone
								}
							}
						}
					}
					homeGetInTouch {
						homeGetInTouchHeading
						homeGetInTouchDescription
					}
					homePopularLocation {
						homePopularLocationHeading
						homePopularLocations {
							... on WpPopularLocation {
								id
								title
								popularLocationsFields {
									locationName
									url
									featuredImage {
										altText
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 30
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
					}
				}
				seoFields {
					metaTitle
					metaDescription
					opengraphDescription
					opengraphTitle
					productSchema
					image {
						altText
						localFile {
							childImageSharp {
								original {
									height
									width
								}
							}
							publicURL
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFields, homepageFields },
	} = data;

	const {
		homeBanner,
		homeContentSection,
		homeLeftVideoAndRightContent,
		homeFeaturedOfficeSpaces,
		homeClientTestimonials,
		homePopularLocation,
		homeMeetTheTeam,
		homeGetInTouch,
	} = homepageFields;

	const { viewport, onLocationChange } = React.useContext(CartContext);

	const featuredProperty1 = data.allAirtableProperties.edges?.filter(
		(prop) =>
			prop.node.fixedId ===
			data.wpFeaturedOffice.featuredOfficeFields.firstFeaturedOffice
	);
	const featuredProperty2 = data.allAirtableProperties.edges?.filter(
		(prop) =>
			prop.node.fixedId ===
			data.wpFeaturedOffice.featuredOfficeFields.secondFeaturedOffice
	);
	const featuredProperty3 = data.allAirtableProperties.edges?.filter(
		(prop) =>
			prop.node.fixedId ===
			data.wpFeaturedOffice.featuredOfficeFields.thirdFeaturedOffice
	);
	const featuredProperty4 = data.allAirtableProperties.edges?.filter(
		(prop) =>
			prop.node.fixedId ===
			data.wpFeaturedOffice.featuredOfficeFields.fourthFeaturedOffice
	);

	const officesTemp = [
		featuredProperty1[0],
		featuredProperty2[0],
		featuredProperty3[0],
		featuredProperty4[0],
	];

	const officestemp2 = officesTemp.filter((e) => e?.node?.fixedId > 0);

	//   const [image1, setImage1] = useState(
	//     data.heroInterior?.localFile.childImageSharp.gatsbyImageData
	//   );
	//   const [image2, setImage2] = useState(
	//     data.heroWindow?.localFile.childImageSharp.gatsbyImageData
	//   );
	//   const [image3, setImage3] = useState(
	//     data.heroExterior?.localFile.childImageSharp.gatsbyImageData
	//   );
	//   const [image1, setImage1] = useState(
	//     data.allAirtableProperties.edges[0].node.photosNew[0].childImageSharp
	//       ?.gatsbyImageData
	//   );
	//   const [image2, setImage2] = useState(
	//     data.allAirtableProperties.edges[1].node.photosNew[0].childImageSharp
	//       ?.gatsbyImageData
	//   );
	//   const [image3, setImage3] = useState(
	//     data.allAirtableProperties.edges[2].node.photosNew[0].childImageSharp
	//       ?.gatsbyImageData
	//   );
	const siteUrl = data.site.siteMetadata.siteUrl;

	const bgImageRef = useRef(null);
	const [filtersExtendedClass, setFiltersExtendedClass] = useState(false);
	const [loadingVisible, setLoadingVisible] = useState(true);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	useEffect(() => {
		onLocationChange(viewport.latitude, viewport.longitude);
	}, []);

	//   useEffect(() => {
	//     // var length1 = getRandomInt(
	//     //   data.allAirtableProperties.edges[0].node.photosNew?.length
	//     // );
	//     // var length2 = getRandomInt(
	//     //   data.allAirtableProperties.edges[1].node.photosNew?.length
	//     // );
	//     // var length3 = getRandomInt(
	//     //   data.allAirtableProperties.edges[2].node.photosNew?.length
	//     // );

	//     setImage1(
	//       addGradient(data.heroInterior?.localFile.childImageSharp.gatsbyImageData)
	//     );
	//     setImage2(
	//       addGradient(data.heroWindow?.localFile.childImageSharp.gatsbyImageData)
	//     );
	//     setImage3(
	//       addGradient(data.heroExterior?.localFile.childImageSharp.gatsbyImageData)
	//     );
	//   }, []);

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.metaTitle}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.localFile.publicURL}`,
							width: `${seoFields?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<div
						style={{ overflow: "hidden" }}
						className={
							filtersExtendedClass
								? "bg-hero-height-hidden-extended position-relative"
								: "bg-hero-height-hidden position-relative"
						}
					>
						<Container
							fluid
							className="px-0 mx-0 position-absolute h-100 top-0"
						>
							<Row style={{ zIndex: 999999 }} className="px-0 h-100 g-0 mx-0">
								<Col>
									<Row className="gx-0 h-100">
										{/* Carousel for mobile view */}
										<Carousel
											controls={false}
											indicators={false}
											interval={4000}
											className="d-md-none h-100"
										>
											{homeBanner.homeBannerBackgroundImage && (
												<Carousel.Item>
													<div
														style={{ overflow: "hidden" }}
														className="w-100 position-relative h-100"
													>
														<BgImage
															style={{ zIndex: -2 }}
															ref={bgImageRef}
															className="w-100 bg-hero-height-hidden"
															image={
																homeBanner?.homeBannerBackgroundImage?.localFile
																	.childImageSharp.gatsbyImageData
															}
														/>
														{/* <div
															style={{ background: "#0000001A" }}
															className="position-absolute w-100 h-100 start-0 top-0"
														></div> */}
													</div>
												</Carousel.Item>
											)}
											{homeBanner.homeBannerBackgroundImage2 && (
												<Carousel.Item>
													<div
														style={{ overflow: "hidden" }}
														className="w-100 position-relative h-100"
													>
														<BgImage
															style={{ zIndex: -2 }}
															ref={bgImageRef}
															className="w-100 bg-hero-height-hidden"
															image={
																homeBanner?.homeBannerBackgroundImage2
																	?.localFile.childImageSharp.gatsbyImageData
															}
														/>
														{/* <div
															style={{ background: "#0000001A" }}
															className="position-absolute w-100 h-100 start-0 top-0"
														></div> */}
													</div>
												</Carousel.Item>
											)}
											{homeBanner.homeBannerBackgroundImage3 && (
												<Carousel.Item>
													<div
														style={{ overflow: "hidden" }}
														className="w-100 position-relative h-100"
													>
														<BgImage
															style={{ zIndex: -2 }}
															ref={bgImageRef}
															className="w-100 bg-hero-height-hidden"
															image={
																homeBanner?.homeBannerBackgroundImage3
																	?.localFile.childImageSharp.gatsbyImageData
															}
														/>
														{/* <div
															style={{ background: "#0000001A" }}
															className="position-absolute w-100 h-100 start-0 top-0"
														></div> */}
													</div>
												</Carousel.Item>
											)}
										</Carousel>

										{/* Static images for larger screens */}
										<Col md={4} className="d-none d-md-block">
											<div
												style={{ overflow: "hidden" }}
												className="w-100 position-relative h-100"
											>
												{homeBanner.homeBannerBackgroundImage && (
													<BgImage
														style={{ zIndex: -2 }}
														ref={bgImageRef}
														className="w-100 h-100"
														image={
															homeBanner?.homeBannerBackgroundImage?.localFile
																.childImageSharp.gatsbyImageData
														}
													/>
												)}
												{/* <div
													style={{ background: "#0000001A" }}
													className="position-absolute w-100 h-100 start-0 top-0"
												></div> */}
											</div>
										</Col>
										<Col
											style={{ paddingLeft: "3px", paddingRight: "3px" }}
											md={4}
											className="d-none d-md-block"
										>
											<div
												style={{ overflow: "hidden" }}
												className="w-100 position-relative h-100"
											>
												{homeBanner.homeBannerBackgroundImage2 && (
													<BgImage
														style={{ zIndex: -2 }}
														ref={bgImageRef}
														className="w-100 h-100"
														image={
															homeBanner?.homeBannerBackgroundImage2?.localFile
																.childImageSharp.gatsbyImageData
														}
													/>
												)}
												{/* <div
													style={{ background: "#0000001A" }}
													className="position-absolute w-100 start-0 top-0 h-100"
												></div> */}
											</div>
										</Col>
										<Col md={4} className="d-none d-md-block">
											<div
												style={{ overflow: "hidden" }}
												className="w-100 position-relative h-100"
											>
												{homeBanner.homeBannerBackgroundImage3 && (
													<BgImage
														style={{ zIndex: -2 }}
														ref={bgImageRef}
														className="w-100 h-100 "
														image={
															homeBanner?.homeBannerBackgroundImage3?.localFile
																.childImageSharp.gatsbyImageData
														}
													/>
												)}
												{/* <div
													style={{ background: "#0000001A" }}
													className="position-absolute w-100  h-100 end-0 top-0"
												></div> */}
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
						<div className="position-absolute start-50 top-50 translate-middle w-100">
							<Container>
								<Row className="padding-above-title justify-content-center">
									{" "}
									<Col md={10} lg={9} xl={7}>
										<div
											className="py-3 px-md-4 px-2 text-center"
											style={{ background: "#0B498266", borderRadius: "32px" }}
										>
											<h1 className="text-white fw-bold display-5 ">
												{homeBanner.homeBannerHeading}
											</h1>
										</div>
										<Row className="justify-content-center padding-above-search">
											<Col md={9} lg={7} xl={8} xxl={7}>
												<div
													className="py-1  ps-sm-3 ps-lg-0 pe-1 pe-sm-3 pe-lg-4 bg-white w-100  my-2"
													style={{
														height: "60px",
														borderRadius: "200px",
														boxShadow: "0px 1px 20px #00000029",
													}}
												>
													<Row className="w-100 h-100 mx-0 mx-sm-2 align-items-center">
														<Col xs={12}>
															<InputFieldHome
																placeholder="Where do you want your next office to be?"
																color={""}
															/>
														</Col>
													</Row>
												</div>
											</Col>
										</Row>
									</Col>
								</Row>
							</Container>
						</div>
					</div>
					{homeLeftVideoAndRightContent &&
						!checkPropertiesForNull(homeLeftVideoAndRightContent, [
							"homeSectionRightHeading",
						]) && (
							<section className="pt-5 pt-lg-7">
								<Container className="px-4 px-md-0">
									<Row>
										<Col>
											<div className="text-center fs-3 pb-5 normal-font">
												<SafeHtmlParser
													htmlContent={
														homeLeftVideoAndRightContent?.aboveVideoContent
													}
												/>
											</div>
										</Col>
									</Row>
									<Row className="mb-6 gx-lg-6 d-flex justify-content-between  align-items-center">
										<Col
											lg={7}
											className="ps-lg-3 mb-4 position-relative mb-md-5 mb-lg-0"
										>
											{loadingVisible === true && (
												<div className="text-center position-absolute start-50 top-50 translate-middle">
													<Spinner animation="border" variant="primary" />
												</div>
											)}

											<div>
												<ReactPlayer
													onReady={() => setLoadingVisible(false)}
													playing
													loop
													controls
													playsinline
													muted
													title="Flex Office Solutions"
													className="w-100 h-auto video-border"
													url={
														homeLeftVideoAndRightContent?.homeSectionLeftVideo
															?.mediaItemUrl
													}
												/>
											</div>
										</Col>
										<Col lg={5} className="ps-xl-6">
											<div>
												<h2 className="mb-4 fs-1">
													{
														homeLeftVideoAndRightContent?.homeSectionRightHeading
													}
												</h2>
												{homeLeftVideoAndRightContent?.homeSectionRightContent?.map(
													(item) => {
														return (
															<div className="d-flex pb-md-2 pb-2 fs-4 py-md-0 me-3 me-md-4 me-lg-2 me-xl-4">
																<img
																	className=" me-2"
																	src={TickIcon}
																	style={{
																		maxWidth: "30px",
																		width: "30px",
																		maxHeight: "30px",
																	}}
																/>
																<p className="fw-bold py-0 pb-2 my-0">
																	{item.items}
																</p>
															</div>
														);
													}
												)}

												<Row className="mt-4 justify-content-center">
													{" "}
													{homeLeftVideoAndRightContent?.homeSectionRightContentContactButton &&
														homeLeftVideoAndRightContent
															?.homeSectionRightContentContactButton.url && (
															<Col md={6} lg={6} xl={6}>
																<Link
																	className="w-100 btn fw-bold rounded-pill text-white bg-primary  py-2 me-5 mb-4"
																	to={
																		homeLeftVideoAndRightContent
																			?.homeSectionRightContentContactButton.url
																	}
																>
																	{
																		homeLeftVideoAndRightContent
																			?.homeSectionRightContentContactButton
																			?.title
																	}
																</Link>
															</Col>
														)}
													{homeLeftVideoAndRightContent?.homeSectionRightContentSearchOfficeButton &&
														homeLeftVideoAndRightContent
															?.homeSectionRightContentSearchOfficeButton
															?.url && (
															<Col md={6} lg={6} xl={6}>
																<Link
																	className="w-100 btn fw-bold rounded-pill border-1 text-white bg-dark-blue  py-2"
																	to={
																		homeLeftVideoAndRightContent
																			?.homeSectionRightContentSearchOfficeButton
																			?.url
																	}
																>
																	{
																		homeLeftVideoAndRightContent
																			?.homeSectionRightContentSearchOfficeButton
																			?.title
																	}
																</Link>
															</Col>
														)}
												</Row>
											</div>
											<div className="d-flex pt-3 pt-md-0 align-items-center">
												<p className="fw-bold fs-5 py-0 my-0">
													Rated{" "}
													<span
														className="fw-bold"
														style={{ color: "#FEA500" }}
													>
														5.0
													</span>
												</p>
												<img
													className="me-3 ms-2"
													src={StarsIcon}
													style={{
														maxWidth: "80px",
													}}
												/>
												<p className="fw-bold fs-5 py-0 my-0">Google</p>
											</div>
										</Col>
									</Row>
								</Container>
							</section>
						)}
					{homeContentSection &&
						!checkPropertiesForNull(homeContentSection, [
							"homeContentSectionTitle",
						]) && (
							<section className="my-0">
								<Container>
									<Row className="justify-content-center">
										<Col className="text-center" lg={10} xl={6}>
											<div className="text-center fs-3  normal-font">
												<SafeHtmlParser
													htmlContent={homeContentSection?.aboveCardsContent}
												/>
											</div>
										</Col>
									</Row>
									<Row className="  mt-md-5 mt-4 gx-md-4 gx-lg-5 px-md-4 px-3">
										{homeContentSection &&
											!checkPropertiesForNull(homeContentSection, [
												"homeContentSectionCards",
											]) && (
												<>
													{homeContentSection?.homeContentSectionCards.map(
														(item, i) => (
															<Col
																md={6}
																lg={4}
																className={
																	i + 1 ===
																	homeContentSection?.homeContentSectionCards
																		.length
																		? "mb-3 mb-md-2 pt-md-5 pt-lg-0"
																		: "mb-3 mb-md-2"
																}
																key={i}
															>
																<div className=" ">
																	<div className="d-flex  mb-3 align-items-center">
																		<img
																			src={item?.cardIcon?.sourceUrl}
																			className="me-2
																			"
																			style={{
																				height: "30px",
																			}}
																		/>
																		<h4 className="d-inline-block pb-0 mb-0">
																			{item?.cardTitle}
																		</h4>
																	</div>
																	<div>
																		<SafeHtmlParser
																			htmlContent={item?.cardDescription}
																		/>
																	</div>
																</div>
															</Col>
														)
													)}
												</>
											)}
									</Row>
									<Row>
										<Col className="text-center">
											<div>
												<Link
													style={{ minWidth: "175px" }}
													className="w-100 w-md-auto btn fw-bold rounded-pill mt-md-5 mt-4 text-white me-md-3 bg-primary px-4 py-2  "
													to={homeContentSection?.ctaButton.url}
												>
													{homeContentSection?.ctaButton?.title}
												</Link>
												<Link
													style={{ minWidth: "175px" }}
													className="w-100 w-md-auto btn fw-bold rounded-pill border-1 mt-md-5 mt-4 text-white bg-dark-blue  py-2"
													to="/calculator"
												>
													Office Calculator
												</Link>
											</div>
										</Col>
									</Row>
								</Container>
							</section>
						)}

					<section className="pt-2 pb-4 pt-4 py-lg-7">
						<Container>
							<Row>
								<Col className="text-center">
									{" "}
									<h2 className="fs-1 mb-5">
										{homeFeaturedOfficeSpaces?.homeFeaturedOfficeSpacesHeading ||
											"Featured Office Spaces"}
									</h2>
								</Col>
							</Row>

							<Row>
								<FeaturedProperties
									CardType={ListingsPropertyCard}
									properties={officestemp2}
								/>
								{/* <Col className="mb-5" lg={6}>
                  <ListingsPropertyCard property={featuredProperty1[0]} />
                </Col>
                <Col className="mb-5" lg={6}>
                  <ListingsPropertyCard property={featuredProperty2[0]} />
                </Col>
                <Col className="mb-5" lg={6}>
                  <ListingsPropertyCard property={featuredProperty3[0]} />
                </Col>
                <Col className="mb-5" lg={6}>
                  <ListingsPropertyCard property={featuredProperty4[0]} />
                </Col> */}
							</Row>
						</Container>
					</section>

					<section className="">
						<Testimonials
							title={homeClientTestimonials?.homeClientTestimonialsHeading}
						/>
					</section>

					{homeMeetTheTeam &&
						!checkPropertiesForNull(homeMeetTheTeam, [
							"homeMeetTheTeamHeading",
						]) && (
							<section className="pt-5 pt-lg-7">
								<Team teamCards={homeMeetTheTeam?.homeMeetTheTeamCards} />
							</section>
						)}
					{homePopularLocation &&
						!checkPropertiesForNull(homePopularLocation, [
							"homePopularLocationHeading",
						]) && (
							<section className="bg-primary py-lg-5  bg-opacity-10">
								<Container className="px-4 px-lg-0">
									<Row className="py-lg-5 py-4">
										<Col className="text-center">
											<h2 className="fs-1">
												{homePopularLocation?.homePopularLocationHeading ||
													"Popular Locations"}
											</h2>
										</Col>
									</Row>
									<Row className="g-md-5 g-3">
										{homePopularLocation?.homePopularLocations &&
											homePopularLocation?.homePopularLocations.length > 0 && (
												<>
													{homePopularLocation?.homePopularLocations.map(
														(item, i) => (
															<Col xs={6} lg={3} key={i}>
																<AnimatedImageCard
																	imageData={
																		item?.popularLocationsFields?.featuredImage
																	}
																	locationTitle={
																		item?.popularLocationsFields?.locationName
																	}
																	locationUrl={
																		item?.popularLocationsFields?.url
																	}
																/>
															</Col>
														)
													)}
												</>
											)}
									</Row>
									<div style={{ height: "3rem" }}></div>
								</Container>
							</section>
						)}

					<section>
						<Container className="my-lg-6 my-3 my-md-5">
							<div
								style={{
									boxShadow: "0px 1px 20px #00000029",
									borderRadius: "12px",
								}}
								className="py-3 py-md-5 px-3 px-md-5 mb-3 bg-white "
							>
								<Row>
									<Col>
										<h3>
											{homeGetInTouch?.homeGetInTouchHeading || "Get in touch"}
										</h3>
									</Col>
								</Row>
								<Row>
									<Col lg={4}>
										<SafeHtmlParser
											htmlContent={homeGetInTouch?.homeGetInTouchDescription}
										/>
									</Col>
								</Row>
								<ContactFormHome />
							</div>
							<ExpertCard />
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default IndexPage;
{
	/* .sort((p1, p2) =>
													getDistanceFromLatLonInKm(
														viewport.latitude,
														viewport.longitude,
														p1.node.locationLatitude,
														p1.node.locationLongitude
													) *
														0.621371 >
													getDistanceFromLatLonInKm(
														viewport.latitude,
														viewport.longitude,
														p2.node.locationLatitude,
														p2.node.locationLongitude
													) *
														0.621371
														? 1
														: getDistanceFromLatLonInKm(
																viewport.latitude,
																viewport.longitude,
																p1.node.locationLatitude,
																p1.node.locationLongitude
														  ) *
																0.621371 <
														  getDistanceFromLatLonInKm(
																viewport.latitude,
																viewport.longitude,
																p2.node.locationLatitude,
																p2.node.locationLongitude
														  ) *
																0.621371
														? -1
														: 0
												) */
}

// .sort((p1, p2) =>
// 												getDistanceFromLatLonInKm(
// 													viewport.latitude,
// 													viewport.longitude,
// 													p1.node.locationLatitude,
// 													p1.node.locationLongitude
// 												) *
// 													0.621371 >
// 												getDistanceFromLatLonInKm(
// 													viewport.latitude,
// 													viewport.longitude,
// 													p2.node.locationLatitude,
// 													p2.node.locationLongitude
// 												) *
// 													0.621371
// 													? 1
// 													: getDistanceFromLatLonInKm(
// 															viewport.latitude,
// 															viewport.longitude,
// 															p1.node.locationLatitude,
// 															p1.node.locationLongitude
// 													  ) *
// 															0.621371 <
// 													  getDistanceFromLatLonInKm(
// 															viewport.latitude,
// 															viewport.longitude,
// 															p2.node.locationLatitude,
// 															p2.node.locationLongitude
// 													  ) *
// 															0.621371
// 													? -1
// 													: 0
// 											)
